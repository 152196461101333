import React, { useState, useEffect, useRef } from 'react';
import './Button.css'; // Import the CSS file for styling the button

const Button = () => {
  const [isVisible, setIsVisible] = useState(false);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      const documentHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      setIsVisible(scrollTop > 0 && scrollTop + windowHeight < documentHeight);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClick = () => {
    window.open('https://book.easytablebooking.com/book/?id=7799e&lang=se', '_blank');
  };

  useEffect(() => {
    const updateButtonWidth = () => {
      const buttonElement = buttonRef.current;
      if (buttonElement) {
        buttonElement.style.width = 'auto'; // Reset width to auto
        const buttonWidth = buttonElement.offsetWidth;
        buttonElement.style.width = `${buttonWidth}px`; // Set width based on text length
      }
    };

    const handleResize = () => {
      setTimeout(updateButtonWidth, 200); // Delay the update by 100ms
    };

    window.addEventListener('resize', handleResize);

    setTimeout(updateButtonWidth, 100); // Initial delay for button width update

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={`button-container ${isVisible ? 'visible' : 'hidden'}`}>
      <button className="book-button" onClick={handleClick} ref={buttonRef}>
        BOKA BORD
      </button>
    </div>
  );
};

export default Button;
