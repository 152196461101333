import React, { useState } from 'react';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Navbar = () => {
  const handleContactClick = () => {
    const footerSection = document.querySelector('.footer-section');
    footerSection.scrollIntoView({ behavior: 'smooth' });
  };

  const handleMenuClick = () => {
    const menuSection = document.querySelector('.menu-section');
    menuSection.scrollIntoView({ behavior: 'smooth' });
  };

  const handleBookTableClick = () => {
    window.open('https://book.easytablebooking.com/book/?id=7799e&lang=se', '_blank');
  };

  return (
    <nav className="navbar">
      <div className="left-buttons">
        <img className="logo" src={process.env.PUBLIC_URL + '/logotype_white.svg'} alt="Logotype"  />
        <button className="left-button" onClick={handleContactClick}><h1>KONTAKT</h1></button>
        <button className="left-button" onClick={handleMenuClick}><h1>MENY</h1></button>
      </div>
      <div className="right-button">
        <button onClick={handleBookTableClick}><h1>BOKA BORD</h1></button>
      </div>
    </nav>
  );
};

// JavaScript code
window.addEventListener('scroll', function () {
  var navbar = document.querySelector('.navbar');
  var scrolled = window.pageYOffset || document.documentElement.scrollTop;
  var windowHeight =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  var documentHeight =
    document.documentElement.scrollHeight || document.body.scrollHeight;
  var isVisible = scrolled > 0 && scrolled + windowHeight < documentHeight;

  if (isVisible) {
    navbar.classList.add('visible');
  } else {
    navbar.classList.remove('visible');
  }
});

const Hero = () => {
  return (
    <header className="hero">
      <video className="video" autoPlay loop muted playsInline>
        <source src={process.env.PUBLIC_URL + '/headerVideo.mp4'} type="video/mp4" />
      </video>

      <a href="https://www.instagram.com/bovsliceshop/" target="_blank" rel="noopener noreferrer" className='alt-logo'>
        <img className='alt-logo-img' src={process.env.PUBLIC_URL + '/icon.svg'} alt="Slice" />
      </a>

    </header>

  );
};

const MenuItem = ({ headerImage, itemImage }) => {
  return (
    <div className="menu-item">
      {headerImage && <img src={headerImage} alt="Header" className="menu-item-header" />}
      {itemImage && <img src={itemImage} alt="Item" className="menu-item-image" />}
    </div>
  );
};

const Time = ({ header, paragraph, paragraph2 }) => {
  return (
    <div>
      <h2>{header}</h2>
      <p>{paragraph}</p>
      <p>{paragraph2}</p>
    </div>
  );
};

const MenuSection = () => {
  return (
    <section className="menu-section">
      <div className="food-subsection">
        <MenuItem
          headerImage={process.env.PUBLIC_URL + '/menu/headers/snacksHeader.svg'}
          itemImage={process.env.PUBLIC_URL + '/menu/items/snacks.svg'}
        />
        <MenuItem
          headerImage={process.env.PUBLIC_URL + '/menu/headers/middlecourseHeader.svg'}
          itemImage={process.env.PUBLIC_URL + '/menu/items/middlecourse.svg'}
        />
        <MenuItem
          headerImage={process.env.PUBLIC_URL + '/menu/headers/pastaPizzaHeader.svg'}
          itemImage={process.env.PUBLIC_URL + '/menu/items/pastapizza.svg'}
        />
        <MenuItem
          headerImage={process.env.PUBLIC_URL + '/menu/headers/dessertHeader.svg'}
          itemImage={process.env.PUBLIC_URL + '/menu/items/dessert.svg'}
        />
      </div>

      <div className="menu-item">
        <img src={process.env.PUBLIC_URL + '/menu/headers/drinksHeader.svg'} alt="Header" className="menu--drink-item-header" />
      </div>
      <div className="drink-subsection">
        <MenuItem headerImage={null} itemImage={process.env.PUBLIC_URL + '/menu/items/drinks_1.svg'} />
        <MenuItem headerImage={null} itemImage={process.env.PUBLIC_URL + '/menu/items/drinks_2.svg'} />
        <MenuItem headerImage={null} itemImage={process.env.PUBLIC_URL + '/menu/items/drinks_3.svg'} />
      </div>

      <div className="menu-item-small">
        <a href="https://www.instagram.com/bovsliceshop/" target="_blank" rel="noopener noreferrer">
          <img className="menu-item-small-image dont-display-logo" src={process.env.PUBLIC_URL + '/icon.svg'} alt="Slice" />
        </a>
        <a href="https://www.instagram.com/bovgbg" target="_blank" rel="noopener noreferrer">
          <img className="menu-item-small-image" src={process.env.PUBLIC_URL + '/bov.svg'} alt="Bov" />
        </a>
        <a href="https://www.instagram.com/bovsliceshop/" target="_blank" rel="noopener noreferrer">
          <img className="menu-item-small-image" src={process.env.PUBLIC_URL + '/slice.svg'} alt="Slice" />
        </a>
      </div>
    </section>
  );
};

const Footer = () => {
  return (
    <footer className="footer-section">
      <div className="time-section">
        <Time header="TISDAG—TORSDAG" paragraph="17.00—SENT"  />
        <Time header="FREDAG" paragraph="16.00—SENT" />
        <Time header="LÖRDAG" paragraph="16.00—SENT" />
        <Time header="SÖNDAG" paragraph="16.00—SENT" />
      </div>
      <div className="contact-icons">
        <a href="tel:+46768987496">
          <i className="fas fa-phone fa-stack fa-4x" style={{ color: '#f7edc3' }}></i>
        </a>
        <a href="mailto:wineanddine@bovdiscobar.com">
          <i className="fas fa-envelope fa-stack fa-4x" style={{ color: '#f7edc3' }}></i>
        </a>
        <a href="https://goo.gl/maps/dxDB7zLXjThYuUuU7">
          <i className="fas fa-map-marker-alt fa-stack fa-4x" style={{ color: '#f7edc3' }}></i>
        </a>
        <a href="https://www.instagram.com/bovdiscobar/">
          <i className="fab fa-instagram fa-stack fa-4x" style={{ color: '#f7edc3' }}></i>
        </a>
        <a href="https://www.facebook.com/bovdiscobar/">
          <i className="fab fa-facebook-f fa-stack fa-4x" style={{ color: '#f7edc3' }}></i>
        </a>
      </div>
    </footer>
  );
};

const App = () => {
  return (
    <div className="app">
      <Navbar />
      <Hero />
      <MenuSection />
      <Footer />
    </div>
  );
};

export default App;
